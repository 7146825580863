<template>
  <div class="math-task">
    <p class="question">{{ question }}</p>

    <!-- Antwortmöglichkeiten (Buttons) -->
    <div v-if="quizMode === 'buttons'" class="answer-buttons">
      <button v-for="(choice, index) in choices" :key="index" @click="checkAnswer(choice)">
        {{ choice }}
      </button>
    </div>

    <!-- Antwort im Eingabemodus -->
    <div v-if="quizMode === 'input'" class="input-container">
      <input v-model="userAnswer" type="tel" placeholder="Antwort eingeben" />
      <button @click="checkAnswer(userAnswer)">Absenden</button>
    </div>

    <p class="feedback" v-if="feedback">{{ feedback }}</p>
  </div>
</template>

<script>
export default {
  props: ['num1', 'num2', 'correctAnswer'],
  data() {
    return {
      userAnswer: '', // Antwortfeld wird bei einer neuen Frage geleert
      choices: [],
      feedback: '',
      quizMode: 'input', // 'input' oder 'buttons' Modus
      question: '', // Frage wird hier gespeichert
    };
  },
  mounted() {
    this.generateQuestion();
    this.generateChoices();
  },
  methods: {
    generateQuestion() {
      // Generiere die Frage auf Basis der Props num1 und num2
      if (this.num1 && this.num2) {
        this.question = `Was ist ${this.num1} × ${this.num2}?`;
      } else {
        this.question = 'Fehler: Keine gültigen Zahlen!';
      }
      this.userAnswer = ''; // Leert das Eingabefeld bei einer neuen Frage
    },
    checkAnswer(choice) {
      const answer = this.quizMode === 'input' ? parseInt(this.userAnswer, 10) : choice;
      const isCorrect = (answer === this.correctAnswer); // Überprüfung der Antwort gegen die correctAnswer-Prop

      this.feedback = isCorrect ? '🌟 Super! Richtig! 🎉' : 'Oops! Versuch es nochmal!';

      setTimeout(() => {
        this.feedback = '';
        this.$emit('next-question');
      }, 1500);
    },
    generateChoices() {
      const correctIndex = Math.floor(Math.random() * 3);
      this.choices = Array(3).fill(null).map((_, index) => {
        return index === correctIndex
            ? this.correctAnswer
            : Math.floor(Math.random() * 100) + 1; // Zufallszahl für falsche Antworten
      });
    }
  },
  watch: {
    num1() {
      this.generateQuestion();
      this.generateChoices();
    },
    num2() {
      this.generateQuestion();
      this.generateChoices();
    }
  }
};
</script>

<style scoped>
.math-task {
  text-align: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.answer-buttons {
  display: flex;
  justify-content: space-around;
}

button {
  background-color: #ff6f61;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  font-size: 1.2em;
}

button:hover {
  background-color: #ff8561;
}

.feedback {
  margin-top: 20px;
  font-size: 1.5em;
  color: #ff6f61;
}
</style>
