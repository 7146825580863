<template>
  <div class="leaderboard">
    <h3>Top Liste</h3>
    <ul>
      <li v-for="(player, index) in leaderboard" :key="index">
        {{ index + 1 }}. {{ player.name }} - {{ player.points }} Punkte
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    leaderboard: {
      type: Array,
      default: () => [] // Stellt sicher, dass ein leeres Array als Standardwert gesetzt wird
    }
  }
};
</script>

<style scoped>
.leaderboard {
  margin-top: 20px;
}

.leaderboard h3 {
  font-size: 1.5em;
  color: #ff6f61;
}

.leaderboard ul {
  list-style: none;
  padding: 0;
}

.leaderboard li {
  font-size: 1.2em;
  margin-bottom: 10px;
}
</style>
