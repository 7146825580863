<template>
  <div>
    <svg width="150" height="150" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="48" stroke="black" stroke-width="2" fill="white" />
      <g id="marks">
        <line v-for="n in 12" :key="n" :transform="'rotate(' + (n * 30) + ' 50 50)'" x1="50" y1="5" x2="50" y2="10" stroke="black" />
      </g>
      <line :transform="'rotate(' + hourHandRotation + ' 50 50)'" x1="50" y1="50" x2="50" y2="25" stroke="black" stroke-width="2" />
      <line :transform="'rotate(' + minuteHandRotation + ' 50 50)'" x1="50" y1="50" x2="50" y2="15" stroke="black" stroke-width="1" />
    </svg>

    <div class="buttons">
      <button v-for="(choice, index) in choices" :key="choice + index" @click="checkAnswer(choice)">
        {{ choice }}
      </button>
    </div>

    <div v-if="feedback" class="feedback">
      <p>{{ feedback }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['hour', 'minute', 'correctAnswer12', 'correctAnswer24'],
  data() {
    return {
      choices: [],
      feedback: '',
    };
  },
  computed: {
    hourHandRotation() {
      return (this.hour % 12) * 30 + this.minute * 0.5;
    },
    minuteHandRotation() {
      return this.minute * 6;
    },
  },
  watch: {
    hour() {
      this.generateChoices();
    },
    minute() {
      this.generateChoices();
    }
  },
  mounted() {
    this.generateChoices();
  },
  methods: {
    // Überprüfung der Antwort
    checkAnswer(choice) {
      const isCorrect = (choice === this.correctAnswer12 || choice === this.correctAnswer24);
      this.$emit('correct-answer', isCorrect); // sendet isCorrect als Argument an die übergeordnete Komponente
      this.feedback = isCorrect ? '🌟 Super! Richtig! 🎉' : 'Oops! Versuch es nochmal!';

      setTimeout(() => {
        this.feedback = '';
        this.$emit('next-question');
      }, 1500);
    },

    generateChoices() {
      const correctIndex = Math.floor(Math.random() * 3);
      this.choices = Array(3).fill(null).map((_, index) => {
        if (index === correctIndex) {
          return Math.random() < 0.5 ? this.correctAnswer12 : this.correctAnswer24;
        } else {
          let randomTime;
          do {
            randomTime = this.randomTime();
          } while (randomTime === this.correctAnswer12 || randomTime === this.correctAnswer24);
          return randomTime;
        }
      });
    },
    randomTime() {
      const randomHour = Math.floor(Math.random() * 12);
      const randomMinute = Math.floor(Math.random() * 12) * 5;
      return `${randomHour === 0 ? 12 : randomHour}:${randomMinute.toString().padStart(2, '0')}`;
    }
  }
};
</script>

<style>
.buttons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

button {
  padding: 15px 30px;
  font-size: 1.2em;
  border: none;
  border-radius: 15px;
  background-color: #ff6f61;
  color: white;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

button:hover {
  transform: scale(1.05);
}

.feedback {
  margin-top: 20px;
  font-size: 1.5em;
  color: #ff6f61;
}
</style>
