<template>
  <div class="name-input">
    <input v-model="nameInput" type="text" placeholder="Dein Name" />
    <button @click="startApp">Starten</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nameInput: '',
    };
  },
  methods: {
    startApp() {
      if (this.nameInput.trim()) {
        this.$emit('start', this.nameInput);
      }
    },
  },
};
</script>

<style scoped>
.name-input {
  margin-top: 150px;
}
input {
  padding: 10px;
  font-size: 1.5em;
  width: 100%;
  margin-bottom: 10px;
}

button {
  padding: 15px 30px;
  font-size: 1.2em;
  background-color: #ff6f61;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 15px;
}
</style>
