<template>
  <div class="dashboard">
    <h2>Hallo, {{ userName }}!</h2>

    <div class="tasks-buttons">
      <!-- Buttons zum Auswählen der Lernaufgabe -->
      <button v-if="!selectedTask" @click="selectTask('math')">Mathe Aufgaben</button>
      <button v-if="!selectedTask" @click="selectTask('clock')">Uhrzeit Lernen</button>
    </div>

    <!-- Mathe Aufgaben mit dynamisch generierten Fragen und Antwortmöglichkeiten -->
    <div v-if="selectedTask === 'math'">
      <MathTask
          :num1="num1"
          :num2="num2"
          :correctAnswer="correctAnswer"
          @correct-answer="addPoints"
          @next-question="generateMathQuestion"
      />
    </div>

    <!-- Uhrzeit Lernen -->
    <div v-if="selectedTask === 'clock'">
      <ClockTask
          :hour="hour"
          :minute="minute"
          :correctAnswer12="correctAnswer12"
          :correctAnswer24="correctAnswer24"
          @correct-answer="addPoints"
      @next-question="generateClockQuestion" />

    </div>

    <!-- Leaderboard Komponente -->
    <LeaderBoard :leaderboard="leaderboard" />
  </div>
</template>

<script>
import MathTask from "@/components/math/MathTask.vue";
import ClockTask from "@/components/clock/ClockTask.vue";
import LeaderBoard from "@/components/LeaderBoard.vue";
import axios from 'axios';

export default {
  components: { MathTask, ClockTask, LeaderBoard },
  props: ['userName'],
  data() {
    return {
      points: 0,
      leaderboard: [],
      selectedTask: null,
      num1: 0,
      num2: 0,
      correctAnswer: null,
      hour: 0,
      minute: 0,
      correctAnswer12: '',
      correctAnswer24: '',
    };
  },
  mounted() {
    // Leaderboard vom Backend laden
    this.getLeaderboard();
  },
  methods: {
    // Generiere eine neue Math-Frage
    generateMathQuestion() {
      this.num1 = Math.floor(Math.random() * 10) + 1;
      this.num2 = Math.floor(Math.random() * 10) + 1;
      this.correctAnswer = this.num1 * this.num2;
    },

    // Generiere eine neue Uhrzeit-Frage
    generateClockQuestion() {
      this.hour = Math.floor(Math.random() * 12);
      this.minute = Math.floor(Math.random() * 12) * 5;
      this.correctAnswer12 = `${this.hour === 0 ? 12 : this.hour}:${this.minute.toString().padStart(2, '0')}`;
      this.correctAnswer24 = `${this.hour + 12}:${this.minute.toString().padStart(2, '0')}`;
    },

    addPoints(isCorrect) {
      if (isCorrect) {
        this.points += 1;
      } else {
        if (this.points > 0) {
          this.points -= 1;
        }
      }
      this.saveToLeaderboard();
    },

    // Leaderboard speichern
    saveToLeaderboard() {
      const existingPlayer = this.leaderboard.find(player => player.name === this.userName);

      if (existingPlayer) {
        existingPlayer.points = Math.max(0, this.points); // Sicherstellen, dass Punkte nicht negativ sind
      } else {
        this.leaderboard.push({ name: this.userName, points: this.points });
      }

      this.leaderboard.sort((a, b) => b.points - a.points);
      this.leaderboard = this.leaderboard.slice(0, 10);

      // Sende die aktualisierte Liste an das Backend
      axios.post('https://mathe.shinrichs.de/save-leaderboard', this.leaderboard)
          .then(response => {
            console.log('Leaderboard erfolgreich gespeichert!', response.data.leaderboard);
          })
          .catch(error => {
            console.error('Fehler beim Speichern des Leaderboards:', error);
          });
    },

    // Lade das Leaderboard vom Backend
    getLeaderboard() {
      axios.get('https://mathe.shinrichs.de/get-leaderboard')
          .then(response => {
            this.leaderboard = response.data;
          })
          .catch(error => {
            console.error('Fehler beim Abrufen des Leaderboards:', error);
          });
    },

    // Auswahl der Aufgabe
    selectTask(task) {
      this.selectedTask = task;
      if (task === 'math') {
        this.generateMathQuestion(); // Erste Math-Frage generieren
      } else if (task === 'clock') {
        this.generateClockQuestion(); // Erste Uhrzeit-Frage generieren
      }
    },
  }
};
</script>

<style scoped>
.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.dashboard {
  text-align: center;
  margin: 20px;
}

.question {
  font-size: 2em;
  color: #ff6f61;
  margin-bottom: 20px;
}

.answer-buttons, .buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

button {
  background-color: #ff6f61;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  font-size: 1.2em;
}

button:hover {
  background-color: #ff8561;
}

.feedback {
  font-size: 1.5em;
  color: #ff6f61;
  margin-top: 20px;
}

.tasks-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}
</style>
