<template>
  <div class="container">
    <h1>🎓 Lern App 🎉</h1>
    <UserInput v-if="!userName" @start="handleStart" />
    <DashboardComp v-else :userName="userName" />
  </div>
</template>

<script>
import UserInput from './components/UserInput.vue';
import DashboardComp from "@/components/DashboardComp.vue";

export default {
  data() {
    return {
      userName: '',
    };
  },
  methods: {
    handleStart(name) {
      this.userName = name;
    },
  },
  components: {
    DashboardComp,
    UserInput,
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  font-family: 'Roboto', sans-serif;
  color: #ff6f61;
}
</style>
